<template>
  <v-app id="inspire">
    <v-main>
      <v-container class="fill-height" fluid>
        <v-row style="min-height: 100vh">
          <v-col
            cols="12"
            sm="6"
            class="left-side d-none d-sm-flex"
            style="justify-content: center; align-items: center"
          >
            <div>
              <v-img src="@/assets/his-logo.png" contain max-width="300" />
            </div>
          </v-col>

          <v-col
            cols="12"
            sm="6"
            class="d-flex"
            style="
              justify-content: center;
              align-items: center;
              flex-direction: column;
            "
          >
            <template v-if="start">
              <div>
                <v-row>
                  <v-col class="txt-login"> Daftar atau masuk </v-col>
                </v-row>

                <v-row class="mt-0">
                  <v-col>
                    <span class="txt-sub-1"> Selamat datang di </span>

                    <span class="txt-sub-2"> Portal HIPMI Jateng </span>
                  </v-col>
                </v-row>

                <v-row class="mt-6">
                  <v-col class="px-10" cols="12">
                    <v-btn
                      class="text-capitalize"
                      color="#36AC87"
                      block
                      x-large
                      :disabled="disableButton"
                      small
                      @click="register"
                    >
                      <span class="white--text"> Registrasi </span>
                    </v-btn>
                  </v-col>
                </v-row>

                <v-row class="mt-6">
                  <v-col class="px-10" cols="12">
                    <v-btn
                      class="text-capitalize"
                      color="#36AC87"
                      block
                      x-large
                      :disabled="disableButton"
                      small
                      outlined
                      @click="start = false"
                    >
                      <span> Masuk </span>
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </template>

            <template v-else>
              <div>
                <v-row>
                  <v-col class="txt-login"> Login </v-col>
                </v-row>

                <v-row class="mt-0">
                  <v-col>
                    <span class="txt-sub-1"> Selamat datang kembali di </span>

                    <span class="txt-sub-2"> Hipmi Information System </span>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-tabs v-model="tab" fixed-tabs>
                      <v-tab key="tab1" class="text-capitalize">
                        Whatsapp
                      </v-tab>

                      <v-tab key="tab2" class="text-capitalize"> Email </v-tab>
                    </v-tabs>

                    <v-tabs-items v-model="tab">
                      <!-- Tab1 Login menggunakan WhatsApp -->
                      <v-tab-item key="tab1">
                        <v-row class="mt-8">
                          <v-col class="px-10">
                            <v-form @submit.prevent="loginWA">
                              <v-text-field
                                v-model="nomorWhatsapp"
                                name="nomor_wa"
                                color="#36AC87"
                                type="text"
                                label="Masukkan Nomor Whatsapp"
                                outlined
                                autocomplete="off"
                                prepend-inner-icon="mdi-whatsapp"
                              />
                            </v-form>
                          </v-col>
                        </v-row>

                        <v-row class="mt-n12">
                          <v-col class="px-10">
                            <span class="txt-sub-5">
                              Magic link akan dikirimkan ke nomor whatsapp Anda.
                            </span>
                          </v-col>
                        </v-row>

                        <v-row v-if="showAlert">
                          <v-col cols="12" class="px-10 my-n5">
                            <v-alert
                              v-model="showAlert"
                              :type="alertType"
                              dismissible
                              class="px-10"
                            >
                              {{ alertText }}
                            </v-alert>
                          </v-col>
                        </v-row>

                        <v-row class="mt-6">
                          <v-col class="px-10" cols="12">
                            <v-btn
                              class="text-capitalize"
                              color="#36AC87"
                              block
                              x-large
                              :disabled="disableButton"
                              small
                              @click="loginWA"
                            >
                              <template v-if="disableButton">
                                <v-progress-circular
                                  indeterminate
                                  color="green"
                                />
                              </template>
                              <template v-else>
                                <span class="txt-btn-login">
                                  Kirim Tautan
                                </span>
                              </template>
                            </v-btn>
                          </v-col>
                        </v-row>

                        <v-row class="mt-0">
                          <v-col class="text-center txt-sub-3">
                            Tidak memiliki akun? Daftar sekarang
                            <span class="txt-sub-4" @click="register">
                              di sini
                            </span>
                          </v-col>
                        </v-row>
                      </v-tab-item>

                      <!-- Tab2 Login menggunakan Email atau Username -->
                      <v-tab-item key="tab2">
                        <v-row class="mt-8">
                          <v-col class="px-10">
                            <v-form @keyup.enter.native="login">
                              <v-text-field
                                v-model="email"
                                name="email"
                                color="#36AC87"
                                type="text"
                                label="Masukan Email Anda"
                                outlined
                                autocomplete="off"
                                prepend-inner-icon="mdi-account"
                              />

                              <v-text-field
                                id="password"
                                v-model="password"
                                outlined
                                label="Masukan Password Anda"
                                name="password"
                                color="#36AC87"
                                :type="showPassword ? 'text' : 'password'"
                                prepend-inner-icon="mdi-lock"
                                :append-icon="
                                  showPassword ? 'mdi-eye' : 'mdi-eye-off'
                                "
                                @click:append="
                                  () => (showPassword = !showPassword)
                                "
                              />
                            </v-form>
                          </v-col>
                        </v-row>

                        <v-row class="mt-n12">
                          <v-col class="text-right px-10">
                            <span class="txt-sub-4"> Lupa Password? </span>
                          </v-col>
                        </v-row>

                        <v-row v-if="showAlert">
                          <v-col cols="12" class="px-10 my-n5">
                            <v-alert
                              v-model="showAlert"
                              :type="alertType"
                              dismissible
                              class="px-10"
                            >
                              {{ alertText }}
                            </v-alert>
                          </v-col>
                        </v-row>

                        <v-row class="mt-6">
                          <v-col class="px-10" cols="12">
                            <v-btn
                              class="text-capitalize"
                              color="#36AC87"
                              block
                              x-large
                              :disabled="disableButton"
                              small
                              @click="login"
                            >
                              <span class="txt-btn-login"> Login </span>
                            </v-btn>
                          </v-col>
                        </v-row>

                        <v-row class="mt-0">
                          <v-col class="text-center txt-sub-3">
                            Tidak memiliki akun? Daftar sekarang
                            <span class="txt-sub-4" @click="register">
                              di sini
                            </span>
                          </v-col>
                        </v-row>
                      </v-tab-item>
                    </v-tabs-items>
                  </v-col>
                </v-row>
              </div>
            </template>
          </v-col>
        </v-row>
        <v-dialog v-model="dialog" width="500">
          <v-card>
            <v-card-title class="text-h5 green">
              Login via whastapp
            </v-card-title>

            <v-card-text class="mt-6 text-center">
              Link login akan dikirimkan ke WhatsApp anda.<br />
              Klik link yang dikirimkan.
            </v-card-text>

            <v-divider />

            <v-card-actions>
              <v-spacer />
              <v-btn color="primary" text @click="dialog = false">
                Oke saya mengerti
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import axios from "axios";
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;

export default {
  name: "Login",

  data: () => ({
    start: true,
    tab: null,
    nomorWhatsapp: "",
    email: "",
    password: "",
    showAlert: false,
    alertType: "success",
    alertText: "Success!",
    showPassword: false,
    disableButton: false,
    dialog: false,
  }),

  created() {
    // Vuex
    // this.$store.commit('login/SET_LOGIN_INFO', 'val')
    // this.$store.getters['login/getLoginInfo']
  },

  methods: {
    login() {
      const vm = this;

      if (this.email.trim() === "" || this.password.trim() === "") {
        // this.email = ''
        // this.password = ''
        return;
      }

      this.disableButton = true;

      const requestBody = {
        email: this.email,
        password: this.password,
      };

      axios
        .post("/v1/auth/login", this.$qs.stringify(requestBody))
        .then((res) => {
          // if (res.data.status === 200 && res.data.data.block_login) {
          //   this.fnShowAlert("error", res.data.data.block_login_msg);
          //   this.disableButton = false;
          //   return;
          // }
          if (res.data.status === 200) {
            if (res.data.data.block_login) {
              this.fnShowAlert("error", res.data.data.block_login_msg);
              this.disableButton = false;
              return;
            } else {
              this.disableButton = false;
              localStorage.setItem("token", res.data.data.member_login_token);
              localStorage.setItem("userinfo", JSON.stringify(res.data.data));
              this.$store.commit("login/SET_LOGIN_INFO", res.data.data);
              vm.$router.push({ name: "Dashboard" });
            }
          }
        })
        .catch((e) => {
          if (typeof e.response.data.error === "object") {
            this.$toast.error(Object.values(e.response.data.error)[0][0]);
          } else {
            this.$toast.error(e.response.data.error);
          }
          this.disableButton = false;
        });
    },

    loginWA() {
      // console.log('loginWA')
      if (this.nomorWhatsapp.trim() === "") {
        return;
      }

      this.disableButton = true;

      const requestBody = {
        phone: this.nomorWhatsapp,
      };

      axios
        .post("/v1/auth/phone-wa/login", requestBody)
        .then((res) => {
          if (res.data.status === 200) {
            this.disableButton = false;
            this.dialog = true;
          }
        })
        .catch((e) => {
          if (typeof e.response.data.error === "object") {
            this.$toast.error(Object.values(e.response.data.error)[0][0]);
          } else {
            this.$toast.error(e.response.data.error);
          }
          this.disableButton = false;
        });
    },

    register() {
      this.$router.push({ name: "Register" });
    },

    fnShowAlert(type, text) {
      this.alertType = type;
      this.alertText = text;
      this.showAlert = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.txt-login {
  @extend .h-4;
  color: $gray-50;
  font-weight: bold;
}

.txt-sub-1 {
  @extend .p-1;
  color: $gray-50;
}

.txt-sub-2 {
  @extend .p-1;
  color: $gray-50;
  font-style: italic;
}

.txt-sub-3 {
  @extend .p-2;
  color: $gray-50;
}

.txt-sub-4 {
  @extend .p-2;
  color: $blue-1;
  cursor: pointer;
  font-weight: bold;
}

.txt-sub-5 {
  @extend .p-2;
  color: $gray-80;
}

.txt-btn-login {
  @extend .h-5;
  color: #ffffff;
  font-weight: bold;
}

.left-side {
  background: url("../../assets/background-login.svg");
}
</style>
